import { styled, Box, Typography, Button, List } from "@mui/material";
import { brighthiveTheme } from "../../../theme/theme";

export const Wrapper = styled("div")`
  padding: 10px 20px;
  min-height: calc(100% - 20px);
  display: flex;
  flex-direction: column;

  .heading {
    padding: 0 0 10px;
    margin: 0 0 20px;
    border-bottom: 1px solid
      ${brighthiveTheme.palette.action.disabledBackground};
  }
`;

export const ButtonHolder = styled("div")``;

export const ContentWrapper = styled("div")`
  padding: 10px 20px;
  overflow: hidden;

  .sub-heading {
    border: none !important;
    font-size: 16px;
    line-height: 24px;
    margin: 0 0 10px;
    color: ${brighthiveTheme.palette.gray.darkest};
  }
`;

export const CompareScoreBox = styled(Box)`
  background: ${brighthiveTheme.palette.customRolled.dark};
  padding: 10px 15px;
  border-radius: 4px;
  min-width: 95px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  line-height: 20px;
  font-weight: 500;
  color: ${brighthiveTheme.palette.common.black};

  &.compare-score {
    width: 95px;
  }

  &.rounded {
    width: 28px;
    min-width: 28px;
    height: 28px;
    padding: 5px;
    font-size: 14px;
    line-height: 18px;
    border-radius: 100%;
  }
`;

export const SubHeader = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${brighthiveTheme.palette.action.disabledBackground};
  overflow: hidden;
  padding: 10px 20px;
`;

export const HeaderCompareScore = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  h6 {
    border: none;
  }
`;

export const EmptyBox = styled("div")`
  padding: 10px 15px;
  border-radius: 4px;
  min-width: 95px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  line-height: 20px;
  font-weight: 500;
  color: transparent;
`;

export const CompareWrapper = styled("div")`
  display: flex;
  flex-direction: column;
`;

export const CompareHeader = styled("div")`
  display: flex;
  width: 100%;
  gap: 10px;
  border-bottom: 1px solid ${brighthiveTheme.palette.action.disabledBackground};
  padding: 0 0 10px;
  margin: 0 0 10px;

  .select-dropdown {
    width: calc(50% - 73px);
    min-width: calc(50% - 73px);
    max-width: calc(50% - 73px);

    li {
      color: red;
    }
  }
`;

export const ComparingListHolder = styled("div")`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 10px;
`;

export const NoRecordBlock = styled(Box)`
  border: 1px solid ${brighthiveTheme.palette.action.disabledBackground};
  padding: 20px;
  flex: 1;
  background: ${brighthiveTheme.palette.gray.light};

  .no-record {
    color: ${brighthiveTheme.palette.gray.darkest};
    display: block;
    font-size: 20px;
    line-height: 1.25;
    font-weight: 500;
    margin: 0;
  }
`;

export const SchemaListItems = styled(List)`
  width: 100%;
  width: calc(50% - 73px);
  min-width: calc(50% - 73px);
  max-width: calc(50% - 73px);
  padding: 0;

  li {
    border-top: 1px solid ${brighthiveTheme.palette.action.disabledBackground};
    border-right: 1px solid ${brighthiveTheme.palette.action.disabledBackground};
    border-left: 1px solid ${brighthiveTheme.palette.action.disabledBackground};
    padding: 10px 20px;
    flex-direction: column;

    &:first-child {
      border-top: 1px solid ${brighthiveTheme.palette.action.disabledBackground};
    }

    &:last-child {
      border-bottom: 1px solid
        ${brighthiveTheme.palette.action.disabledBackground};
    }
  }

  &.compare-response {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;

    > div {
      align-items: center;
      width: 100%;

      li {
        border-width: 1px 1px 0;
        border-style: solid;
        border-color: ${brighthiveTheme.palette.action.disabledBackground};
      }

      &:last-child {
        li {
          border-bottom-width: 1px;
        }
      }
    }

    /* li {
      align-items: center;
    } */

    .custom-compare-box {
      margin: 0 10px;
      height: 20px;
      max-height: 20px;
    }
  }
`;

export const SchemaName = styled("span")`
  display: block;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  font-weight: 500;
  color: ${brighthiveTheme.palette.common.black};
  width: 100%;
  word-break: break-all;
`;

export const SchemaType = styled("span")`
  display: block;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.25px;
  width: 100%;
  color: ${brighthiveTheme.palette.common.black};
`;
